
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { TableData, ResourcesLocation } from '@/types/dispatch'
import { ElForm } from 'element-ui/types/form'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'dispatchVerify',
  components: { MapLocation }
})
@KeepAlive

export default class DispatchVerify extends Vue {
  private infoData: TableData | {}= {}
  private resourcesLocationList: ResourcesLocation[] = []
  private info = {
    verify: '',
    resultDescription: '',
    dispatchGrade: '',
    scenariosId: ''
  }

  private submitShow = false

  private rules = {
    verify: [
      { required: true, message: '请选择是否通过', trigger: 'change' }
    ],
    scenariosId: [
      { required: true, message: '请选择对应预案', trigger: 'change' }
    ]
  }

  private gradeList = []
  private disposeList = []

  get dispatchId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getGradeList()
    this.getDetail()
  }

  // 事件等级
  getGradeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'dispatchGrade'
    }).then(res => {
      this.gradeList = res.dispatchGrade || []
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.dispatch.selectDispatchByDispatchId, {
      dispatchId: this.dispatchId
    }).then(res => {
      if (res) {
        this.infoData = res
        this.resourcesLocationList = [{
          amount: 1,
          longitude: res.longitude,
          latitude: res.latitude
        }]
        this.getDisposeList(res.typeId)
      }
    })
  }

  // 预案列表
  getDisposeList (typeId: string) {
    this.$axios.get(this.$apis.dispatch.selectDispatchScenariosByList, {
      typeId
    }).then(res => {
      this.disposeList = res.list || []
    })
  }

  // 匹配预案后去预案详情
  toDetail () {
    console.log(this.info.scenariosId)
    if (this.info.scenariosId) {
      this.$router.push({
        name: 'dispatchScenariosDetail',
        params: { scenariosId: this.info.scenariosId }
      })
    } else {
      this.$message.warning('请选择对应预案！')
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const info = this.info.verify === '1' ? {
          ...this.info,
          dispatchId: this.dispatchId
        } : {
          verify: this.info.verify,
          resultDescription: this.info.resultDescription,
          dispatchId: this.dispatchId
        }
        this.$axios.post(this.$apis.dispatch.verifyDispatch, info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'dispatchList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
